import React from "react";

function PrivacyPolicy() {
  return (
    <div>
      <h3>Syarat & Ketentuan</h3>
      <b>Syarat dan Ketentuan Penggunaan</b>
      <p>
        Website (Situs) ini dioperasikan oleh Emkay. Saat Anda menggunakan Situs
        ini, Anda setuju dengan syarat dan ketentuan yang tertera, termasuk
        syarat dan ketentuan serta kebijakan tambahan yang dirujuk di sini
        dan/atau tersedia melalui tautan seperti Kebijakan Privasi dan
        Pemberitahuan Cookie kami (secara kolektif disebut sebagai "Syarat dan
        Ketentuan"). Jika Anda tidak setuju dengan Syarat dan Ketentuan ini,
        Anda harus segera berhenti menggunakannya. Situs ini ditawarkan kepada
        Anda dengan syarat Anda menerima tanpa modifikasi syarat, ketentuan, dan
        pemberitahuan yang terdapat di sini. Produk kami hanya untuk digunakan
        oleh orang dewasa. Dengan menyetujui Syarat dan Ketentuan ini, Anda
        menyatakan bahwa Anda setidaknya sudah mencapai usia dewasa atau lebih
        tua di yurisdiksi Anda. Tanggung jawab Anda untuk memeriksa dan memahami
        hukum setempat di yurisdiksi Anda terkait usia legal untuk menggunakan,
        membeli dari atau mengakses situs kami. <br />
        Syarat dan Ketentuan ini berlaku untuk semua pengguna, termasuk tanpa
        batasan pengguna yang adalah browser, vendor, pelanggan, pedagang,
        dan/atau kontributor konten. Emkay berhak memperbarui Syarat dan
        Ketentuan kapan saja tanpa pemberitahuan kepada Anda.
      </p>
      <b>Struktur Organisasi Kami</b>
      <p>
        Sepanjang ini, istilah "Emkay", "anak perusahaan Emkay", "kami", "kita",
        dan "milik kami" merujuk, sesuai konteks, pada Emkay atau salah satu
        atau lebih anak perusahaannya. Produk dan posisi yang ditampilkan ini
        adalah produk atau posisi dari bisnis terpisah yang dioperasikan oleh
        anak perusahaan terkait.
      </p>
      <b>Tautan ke Situs Pihak Ketiga</b>
      <p>
        Situs ini mencakup tautan ke situs lain, beberapa dioperasikan oleh anak
        perusahaan Emkay dan beberapa dioperasikan oleh pihak ketiga. Tautan ini
        disediakan sebagai kenyamanan bagi Anda dan sebagai jalan tambahan untuk
        mengakses informasi yang terdapat di situs-situs tersebut. Kami belum
        tentu meninjau semua informasi di situs lain dan tidak bertanggung jawab
        atas konten dari situs lain atau produk atau layanan yang mungkin
        ditawarkan melalui situs lain. Situs pihak ketiga mungkin berisi
        informasi yang tidak kami setujui. Penambahan tautan ke situs lain tidak
        boleh dianggap sebagai dukungan terhadap konten situs yang ditautkan.
        Syarat dan ketentuan yang berbeda mungkin berlaku untuk penggunaan Anda
        atas situs yang ditautkan. Anda memahami bahwa semua informasi (seperti
        file data, teks tertulis, perangkat lunak komputer, musik, file audio
        atau suara lainnya, foto, video atau gambar lainnya) yang mungkin Anda
        akses melalui tautan ke situs lain adalah tanggung jawab penuh dari
        orang yang asalnya konten tersebut. Emkay tidak bertanggung jawab atas
        isi dari situs yang ditautkan atau tautan apa pun yang terdapat di situs
        yang ditautkan, atau perubahan atau pembaruan apa pun pada situs-situs
        tersebut. Emkay tidak bertanggung jawab atas webcast atau bentuk
        transmisi lain yang diterima dari situs yang ditautkan. <br />
        Demikian pula, kami dapat memberi Anda akses ke alat pihak ketiga
        opsional yang tidak kami pantau atau kendalikan atau masukkan. Anda
        mengakui dan setuju bahwa alat-alat tersebut disediakan "sebagaimana
        adanya" dan "sebagaimana tersedia" tanpa ada dukungan dari kami. Kami
        tidak akan bertanggung jawab atas apa pun yang timbul dari atau terkait
        dengan penggunaan Anda atas alat pihak ketiga tersebut. Setiap
        penggunaan oleh Anda atas alat opsional yang ditawarkan melalui Situs
        sepenuhnya merupakan risiko dan kebijaksanaan Anda sendiri
      </p>
      <b>Tujuan Situs Ini</b>
      <p>
        Situs ini dioperasikan untuk tujuan menyediakan informasi umum tentang
        kami. Jika Syarat dan Ketentuan ini dianggap sebagai tawaran untuk
        menjual, atau permintaan untuk membeli, produk apa pun dari Emkay,
        penerimaan oleh Emkay secara tegas dibatasi pada Syarat dan Ketentuan
        yang tercantum di sini. Produk tersebut dijual hanya sesuai dengan hukum
        yurisdiksi tertentu di mana mereka dijual.
      </p>
      <b>AKURASI, KELENGKAPAN, DAN KETEPATAN WAKTU INFORMASI DI SITUS INI</b>
      <p>
        KAMI TIDAK DAPAT MENJAMIN BAHWA INFORMASI YANG KAMI SEDIAKAN DI SITUS
        INI SEPENUHNYA AKURAT, LENGKAP, ATAU TERKINI. MATERI DI SITUS INI
        DISEDIAKAN UNTUK INFORMASI UMUM SAJA DAN TIDAK BOLEH DIANDALKAN ATAU
        DIGUNAKAN SEBAGAI SATU-SATUNYA DASAR UNTUK MEMBUAT KEPUTUSAN YANG
        SIGNIFIKAN TANPA BERKONSULTASI DENGAN SUMBER INFORMASI PRIMER YANG LEBIH
        AKURAT, LEBIH LENGKAP, ATAU LEBIH TERKINI. SETIAP KETIDAKPASTIAN ATAS
        MATERI DI SITUS INI HARUS ANDA TANGGUNG RISIKONYA SENDIRI. SITUS INI
        MUNGKIN BERISI INFORMASI SEJARAH. INFORMASI SEJARAH TIDAK TENTU SAJA
        TERKINI DAN DISEDIAKAN UNTUK REFERENSI ANDA SAJA. KAMI BERHAK UNTUK
        MEMODIFIKASI KONTEN SITUS SETIAP SAAT, TETAPI KAMI TIDAK MEMILIKI
        KEWAJIBAN UNTUK MEMPERBARUI INFORMASI APA PUN DI SITUS INI. ANDA SETUJU
        BAHWA ADALAH TANGGUNG JAWAB ANDA UNTUK MEMANTAU PERUBAHAN PADA SITUS.
      </p>
      <b>Penggunaan Materi dari Situs Ini</b>
      <p>
        Situs ini (termasuk semua isinya) adalah milik Emkay atau pemberi
        lisensinya dan dilindungi oleh undang-undang hak cipta, merek dagang,
        dan hukum lain di Indonesia dan negara lainnya. Kami memberi wewenang
        kepada Anda untuk menjelajahi situs ini dan mencetak serta mengunduh
        salinan materi di situs untuk penggunaan pribadi, non-publik, dan
        non-komersial saja, selama Anda tidak menghapus pemberitahuan hak cipta
        atau pemberitahuan lain yang muncul pada materi yang Anda cetak atau
        unduh. Anda setuju bahwa Anda tidak akan menyalin, menampilkan, atau
        mengirimkan materi apa pun di situs ini dengan cara atau media apa pun
        selain untuk tujuan di atas. Anda juga setuju untuk tidak mengubah,
        menyalin, mengirimkan, menampilkan, menampilkan, mereproduksi,
        menerbitkan, melisensikan, membuat karya turunan, mentransfer, menjual,
        menyiarkan, atau mendistribusikan materi dan perangkat lunak apa pun di
        situs ini dengan cara atau media apa pun, termasuk dengan mengunggah
        materi atau dengan cara lain membuat materi tersedia secara online.
      </p>
      <b>Penggunaan yang Dilarang</b>
      <p>
        Selain penggunaan terbatas materi hak cipta atau merek dagang dari situs
        ini, Anda tidak boleh menggunakan produk atau layanan kami untuk tujuan
        ilegal atau tidak sah atau dengan cara yang melanggar hukum di
        yurisdiksi Anda. Secara khusus, Anda dilarang menggunakan situs atau
        isinya: (a) untuk tujuan yang melanggar hukum atau untuk mengajak orang
        lain melakukan atau berpartisipasi dalam tindakan yang melanggar hukum;
        (b) untuk melanggar peraturan internasional, federal, provinsi atau
        negara bagian, aturan, hukum, atau peraturan lokal; (c) untuk
        melecehkan, menyalahgunakan, menghina, merugikan, mencemarkan nama baik,
        memfitnah, menghina, mengintimidasi, atau mendiskriminasi berdasarkan
        jenis kelamin, orientasi seksual, agama, etnis, ras, usia, asal
        kebangsaan, atau cacat; (d) untuk mengunggah atau mengirimkan virus atau
        jenis kode berbahaya lainnya yang akan atau dapat digunakan dengan cara
        apa pun yang akan memengaruhi atau mengganggu fungsionalitas, keamanan,
        atau pengoperasian situs; (e) untuk mengumpulkan atau melacak informasi
        pribadi orang lain; (f) untuk melanggar paten, hak cipta, hak
        publisitas, merek dagang, rahasia dagang, atau hak pihak ketiga lainnya;
        atau (g) membantu pihak ketiga yang mungkin terlibat dalam aktivitas
        yang dilarang oleh Perjanjian ini. Kami berhak untuk mengakhiri
        penggunaan situs oleh Anda untuk melanggar penggunaan yang dilarang ini.
      </p>
      <b>Akun Anggota, Kata Sandi, dan Keamanan</b>
      <p>
        Jika salah satu layanan yang disediakan oleh Emkay dan/atau situs ini
        memerlukan Anda untuk membuka akun, Anda harus menyelesaikan proses
        pendaftaran dengan memberikan kami informasi terkini, lengkap, dan
        akurat sebagaimana diminta oleh formulir pendaftaran yang berlaku. Anda
        juga akan memilih kata sandi dan nama pengguna. Anda sepenuhnya
        bertanggung jawab untuk menjaga kerahasiaan kata sandi dan akun Anda.
        Selain itu, Anda sepenuhnya bertanggung jawab atas semua aktivitas yang
        terjadi di bawah akun Anda. Anda setuju untuk segera memberi tahu Emkay
        tentang penggunaan yang tidak sah atas akun Anda atau pelanggaran
        keamanan lainnya. Emkay tidak akan bertanggung jawab atas kerugian yang
        mungkin Anda alami sebagai akibat dari seseorang yang menggunakan kata
        sandi atau akun Anda, baik dengan atau tanpa sepengetahuan Anda. Namun,
        Anda dapat bertanggung jawab atas kerugian yang dialami oleh Emkay atau
        pihak lain karena seseorang menggunakan akun atau kata sandi Anda. Anda
        tidak boleh menggunakan akun orang lain kapan saja, tanpa izin dari
        pemegang akun tersebut. Anda dapat diminta untuk mengubah kata sandi
        Anda dari waktu ke waktu.
      </p>
      <b>Produk dan Jasa</b>
      <p>
        Harga untuk produk dan layanan kami dapat berubah tanpa pemberitahuan.
        Kami berhak setiap saat untuk memodifikasi atau menghentikan layanan
        (atau bagian atau kontennya) tanpa pemberitahuan sewaktu-waktu. Kami
        tidak akan bertanggung jawab kepada Anda atau kepada pihak ketiga mana
        pun atas perubahan harga, penangguhan, atau penghentian layanan.
      </p>
      <b>Keterbatasan Tanggung Jawab</b>
      <p>
        Situs ini disediakan "sebagaimana adanya" dan "sebagaimana tersedia".
        KAMI TIDAK MENJAMIN, MENGUNGKAPKAN, ATAU MENYATAKAN BAHWA PENGGUNAAN
        ANDA ATAS SITUS KAMI TIDAK AKAN TERGANGGU, TEPAT WAKTU, AMAN, ATAU BEBAS
        DARI KESALAHAN. KAMI TIDAK MENJAMIN BAHWA HASIL YANG DAPAT DIPEROLEH
        DARI PENGGUNAAN SITUS AKAN AKURAT ATAU DAPAT DIANDALKAN. ANDA SETUJU
        BAHWA DARI WAKTU KE WAKTU KAMI DAPAT MENGHAPUS SITUS UNTUK PERIODE WAKTU
        YANG TIDAK DITENTUKAN ATAU MENGHENTIKAN SITUS SETIAP SAAT TANPA
        PEMBERITAHUAN KEPADA ANDA. <br />
        Syarat dan Ketentuan ini mencakup tetapi tidak terbatas pada semua
        ketentuan yang disebutkan di atas. Anda setuju bahwa penggunaan Anda
        atas, atau ketidakmampuan Anda untuk menggunakan ini adalah risiko Anda
        sendiri. Anda juga setuju bahwa kami dapat menanggung tanggung jawab
        atas kerugian yang terjadi sebagai akibat dari penggunaan Anda. Kami
        tidak akan bertanggung jawab atas kerugian yang mungkin Anda alami
        sebagai akibat dari seseorang yang menggunakan kata sandi atau akun
        Anda, baik dengan atau tanpa sepengetahuan Anda.
      </p>
      <b>Ganti Rugi</b>
      <p>
        Anda setuju untuk mengganti rugi, membela, dan membebaskan kami serta
        induk perusahaan, anak perusahaan, afiliasi, mitra, pejabat, direktur,
        agen, kontraktor, pemberi lisensi, penyedia layanan, subkontraktor,
        pemasok, magang, dan karyawan kami, dari setiap klaim atau tuntutan,
        termasuk biaya pengacara yang wajar, yang dibuat oleh pihak ketiga
        karena atau timbul dari pelanggaran Anda terhadap Syarat dan Ketentuan
        ini atau dokumen-dokumen yang dimasukkan sebagai referensi, atau
        pelanggaran Anda terhadap hukum atau hak-hak pihak ketiga.
      </p>
      <b>Ketentuan Umum</b>
      <p>
        Syarat dan Ketentuan ini, serta setiap kebijakan atau aturan operasional
        yang diposting oleh kami atau sehubungan dengan ini, merupakan
        keseluruhan kesepakatan dan pengertian antara Anda dan kami dan mengatur
        penggunaan Anda atas ini, menggantikan perjanjian, komunikasi, dan
        usulan sebelumnya atau saat ini, baik lisan maupun tertulis, antara Anda
        dan kami (termasuk, namun tidak terbatas pada, versi sebelumnya dari
        Syarat dan Ketentuan). <br />
        Setiap kegagalan kami untuk melaksanakan atau menegakkan hak atau
        ketentuan dari Syarat dan Ketentuan ini tidak akan merupakan pengabaian
        hak atau ketentuan tersebut. Syarat dan Ketentuan ini akan diberlakukan
        sesuai dengan hukum yang berlaku di Indonesia, tanpa memperhatikan
        konflik ketentuan hukumnya. Setiap perselisihan yang timbul sehubungan
        dengan Syarat dan Ketentuan ini akan diselesaikan secara eksklusif di
        pengadilan yang berwenang di Indonesia. <br />
        Jika ada ketentuan dari Syarat dan Ketentuan ini yang dianggap melanggar
        hukum, batal, atau tidak dapat diberlakukan, ketentuan tersebut tetap
        dapat diberlakukan sejauh diizinkan oleh hukum yang berlaku, dan bagian
        yang tidak dapat diberlakukan akan dianggap dipisahkan dari Syarat dan
        Ketentuan ini, keputusan tersebut tidak akan mempengaruhi keabsahan dan
        keberlakuan ketentuan lainnya yang tersisa.
      </p>
      <b>Hubungi Kami</b>
      <p>
        Jika Anda memiliki pertanyaan tentang Syarat dan Ketentuan ini, silakan
        hubungi kami di:
      </p>
      <b>Emkay</b>
      <p>
        Taman Tekno Boulevard Blok H7/No 1, BSD City, Setu, Tangerang Selatan,
        Banten, Indonesia Telepon: 087840338440 <br />
        Kami berkomitmen untuk menyediakan pengalaman terbaik bagi pengguna
        kami. Harap membaca Syarat dan Ketentuan ini dengan seksama sebelum
        menggunakan situs kami, dan jangan ragu untuk menghubungi kami jika Anda
        memerlukan klarifikasi lebih lanjut atau memiliki pertanyaan lain.
      </p>
    </div>
  );
}

export default PrivacyPolicy;
