

import React from 'react'

function About() {
  return (
    <div>
        <h3>About Teenz</h3>
        <p>
        Products Bloom is the early stage where a person begins a new experience, including facing skin problems for the first time. Skin in teens has differences with skin in adult
children because hormonal changes during puberty can
cause various skin problems, such as oily skin, acne,
dullness, and various other problems that sometimes
interfere with the appearance of everyday teenagers.
        </p>
        <p>
        Teenz is here as the teen skincare expert who best
understands your needs for her best performance every
day! Five series of TEENZ products from natural
ingredients that are specially formulated for facial skin
care ranging from Moisturizer, Facial wash, Sunscreen,
Acne Gel and Face Mist Toner are able to take care of your
teen's face appearance more #PedeToTheMax & always
cool everyday because TEENZ KNOW YOU BETTER!!
        </p>

        <h3>
        Teenz Facial Wash
        </h3>
        <p>
        Teenz Facial Wash
Sabun wajah yang diformulasikan dengan Mild Surfactant
mengandung Trehalose, d-Panthenol, dan Salix Alba (Willow)
Bark yang efektif membersihkan kulit wajah dengan lembut,
menenangkan kulit, dan merawat skin barrier.
        </p>
    </div>
  )
}

export default About
